import {gsap} from 'gsap';
import SimplexNoise from "simplex-noise";
import {spline} from "@georgedoescode/spline";


export function shapesAnimation(container = document, selector = '.slide-shape-test path') {
  const mapRange1 = gsap.utils.mapRange(25, 175, 0, 1);
  const mapRangeNoise = gsap.utils.mapRange(-0.1, 1.1, 0, 1);
  const paths = container.querySelectorAll(selector);
  let hueNoiseOffset = 0;
  let noiseStep = 0.001;

  const simplex = new SimplexNoise();

  function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
  }

  function noise(x, y) {
    return simplex.noise2D(x, y);
  }

  function createPoints() {
    const points = [];
    // how many points do we need
    const numPoints = 6;
    // used to equally space each point around the circle
    const angleStep = (Math.PI * 2) / numPoints;
    // the radius of the circle
    const rad = 75;

    for (let i = 1; i <= numPoints; i++) {
      // x & y coordinates of the current point
      const theta = i * angleStep;
      const x = mapRange1(100 + Math.cos(theta) * rad);
      const y = mapRange1(100 + Math.sin(theta) * rad);

      // store the point's position
      points.push({
        x: x,
        y: y,
        // we need to keep a reference to the point's original point for when we modulate the values later
        originX: x,
        originY: y,
        // more on this in a moment!
        noiseOffsetX: Math.random() * 1000,
        noiseOffsetY: Math.random() * 1000
      });
    }

    return points;
  }

  const animatePath = (paths) => {
    for (const path of paths) {
      const points = createPoints();
      const animate = () => {
        path.setAttribute("d", spline(points, 1, true));

        // for every point...
        for (let i = 0; i < points.length; i++) {
          const point = points[i];

          // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
          const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
          const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
          // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
          const x = mapRangeNoise(map(nX, -1, 1, point.originX - 0.1, point.originX + 0.1));
          const y = mapRangeNoise(map(nY, -1, 1, point.originY - 0.1, point.originY + 0.1));

          // update the point's current coordinates
          point.x = x;
          point.y = y;

          // progress the point's x, y values through "time"
          point.noiseOffsetX += noiseStep;
          point.noiseOffsetY += noiseStep;
        }


        hueNoiseOffset += noiseStep / 6;

        requestAnimationFrame(animate);
      }
      requestAnimationFrame(animate);
    }
  }
  animatePath(paths)

}
