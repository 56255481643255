export function generatePageRef(container = document) {
  const refs = {};
  const refElements = container.querySelectorAll('[ph-ref]');
  let index = 1;
  for (let refElement of refElements) {
    const content = refElement.innerHTML?.trim();
    if (!content) continue;
    if (!refs[content]) {
      refs[content.trim()] = index++;
    }
    const sup = document.createElement('SUP');
    sup.textContent = refs[content.trim()];
    // console.log("sup", sup, refElement);
    refElement.parentNode.replaceChild(sup, refElement);

  }
  if (index > 1) {
    const refEl = container.querySelector('.references');
    refEl.innerHTML += Object.entries(refs).map(([content, i]) => `<div class="reference-item"><sup>${i}</sup> ${content}</div>`).join('');
    refEl.classList.remove('hidden');
  }
}
