import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {DrawSVGPlugin} from 'gsap/DrawSVGPlugin';
import {getElementsForAnimation} from '../../functions/getElementsForAnimation';
import {debounce} from "lodash";

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);


export function drawDottedLineAnimation(container = document, selector = '.dots-animation-i-e') {
  const dottedLines = getElementsForAnimation(container, selector);

  for (let dottedLine of dottedLines) {
    gsap.fromTo(dottedLine, {
      clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"
    }, {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      duration: 2,
      ease: "linear",
      scrollTrigger: {
        trigger: dottedLine,
        start: "top center",
        end: "bottom center", // scrub: .5,
      }
    });
  }

}

const siteDots = document.querySelectorAll(".dot-line-animation");
const addLineDots = debounce(() => {
  if (window.innerWidth >= 600) {
    siteDots.forEach((element) => {
      const xs = [];
      const resulotion = 100;
      for (let i = 0; i <= resulotion; i++) {
        xs.push(i * window.innerWidth / resulotion);
      }

// time
      let t = 0;

      function animate() {

        let points = xs.map(x => {

          let y = 80 + 60 * Math.sin((x + t) / 200);
          return [x, y];
        });

        let path = "M" + points.map(p => {
          return p[0] + "," + p[1];
        }).join(" L");

        element.setAttribute("d", path);

        t += 1;

        // looping the 'animate' function
        requestAnimationFrame(animate);
      }

      animate();
    });
  } else {
    siteDots.forEach((element) => {
      const xs = [];
      const resulotion = 100;
      for (let i = 0; i <= resulotion; i++) {
        xs.push(i * window.innerWidth / resulotion);
      }

// time
      let t = 0;

      function animate() {

        let points = xs.map(x => {

          let y = 30 + 20 * Math.sin((x + t) / 100);
          return [x, y];
        });

        let path = "M" + points.map(p => {
          return p[0] + "," + p[1];
        }).join(" L");

        element.setAttribute("d", path);

        t += 1;

        // looping the 'animate' function
        requestAnimationFrame(animate);
      }

      animate();
    });

  }

}, 100);
window.addEventListener("resize", addLineDots);

addLineDots();
